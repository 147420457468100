export const NEWS_PATH = '/news'
export const NEWS_SEARCH_PATH = `${NEWS_PATH}/search`
export const NEWS_TODAYPICK_PATH = `${NEWS_PATH}/today-pick`
export const NEWS_TOP_PATH = `${NEWS_PATH}/top`
export const NEWS_RECOMMEND_PATH = `${NEWS_PATH}/recommend`

export const newsSearchPath = ({ query }: { query?: string } = {}) => {
  const searchParams = new URLSearchParams()
  if (query) {
    searchParams.set('query', query)
  }
  const params = searchParams.toString()
  return params ? `${NEWS_SEARCH_PATH}?${params}` : NEWS_SEARCH_PATH
}
