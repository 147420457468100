import * as React from 'react'

import type { NewsCountryFieldsFragment } from '@/gql/generated-news'
import {
  ModalContainer,
  ModalBaseProps,
  ModalContent,
  ModalHeader,
} from '@/components/common/modal'
import { useGetByLocaleName } from '@/utils/language'
import useLanguage from '@/utils/language/language-hook'
import sendGaCustomEvent from '@/service/event-log/gtag-custom-event'

import StyledNewsCountriesModal from './styled'

type Props = ModalBaseProps & {
  countries: (NewsCountryFieldsFragment | null)[]
  onClick: (country: NewsCountryFieldsFragment) => void
  selectedCountryCode?: string
}

const NewsCountriesModal = ({
  isOpen,
  onClose,
  onClick,
  countries,
  selectedCountryCode,
}: Props) => {
  const { t } = useLanguage('news')
  const getByLocaleName = useGetByLocaleName()
  return (
    <ModalContainer isOpen={isOpen} onClose={onClose} pcWidth={300}>
      <ModalContent header={<ModalHeader onClose={onClose} title={t('selectCountry')} />}>
        <StyledNewsCountriesModal>
          {countries.map((country) => {
            return (
              <React.Fragment key={country?.id}>
                {country?.name && (
                  <li>
                    <button
                      className={`--flex-x-center${
                        country.countryCode === selectedCountryCode ? ' active' : ''
                      }`}
                      type="button"
                      onClick={() => {
                        sendGaCustomEvent({
                          gaCategory: 'NEWS',
                          gaAction: 'NEWS_COUNTRY_CLICK',
                          gaLabel: `NEWS_COUNTRY_${country.name.en}`,
                        })
                        onClick(country)
                        onClose()
                      }}>
                      {country.imageUrl && (
                        <img src={country.imageUrl} alt={getByLocaleName(country.name)} />
                      )}
                      <span>{getByLocaleName(country.name)}</span>
                    </button>
                  </li>
                )}
              </React.Fragment>
            )
          })}
        </StyledNewsCountriesModal>
      </ModalContent>
    </ModalContainer>
  )
}

export default NewsCountriesModal
