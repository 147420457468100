import * as React from 'react'
import Router from 'next/router'
import { Button } from '@therich-team/design-system'

import useLanguage from '@/utils/language/language-hook'
import NewsCountries from '@/components/shape/news/news-countries'
import { MY_NEWS_PATH } from '@/route/my'
import { NEWS_RECOMMEND_PATH, NEWS_TODAYPICK_PATH, NEWS_TOP_PATH } from '@/route/news'

import StyledNewsNav from './styled'

type Props = {
  selectedMenu: Menu
  onChangeMenu: (menu: Menu) => void
  onChangeCountry?: boolean | ((countryCode: string) => void)
  excludeMenu?: Menu[]
  hasIcon?: boolean
  changePath?: boolean
}

export type Menu = 'my' | 'today-pick' | 'top' | 'recommend'
export type MenuWithoutMy = Exclude<Menu, 'my'>

export const menu: Menu[] = ['my', 'today-pick', 'top', 'recommend']
export const menuWithoutMy: MenuWithoutMy[] = ['today-pick', 'top', 'recommend']
export const menuIcon: Record<Menu, string> = {
  my: '',
  ['today-pick']: '/images/icons/done.svg',
  top: '/images/icons/fire.svg',
  recommend: '/images/icons/like-thumb.svg',
}
export const newsPaths: Record<Menu, string> = {
  ['today-pick']: NEWS_TODAYPICK_PATH,
  top: NEWS_TOP_PATH,
  recommend: NEWS_RECOMMEND_PATH,
  my: MY_NEWS_PATH,
}

const NewsNav = ({
  selectedMenu,
  onChangeMenu,
  onChangeCountry,
  excludeMenu,
  hasIcon = false,
  changePath = false,
}: Props) => {
  const { t } = useLanguage('news')

  return (
    <StyledNewsNav className="--flex-y-between">
      <ul className="--mobile-horizontal-scroll">
        {menu.map((m) => (
          <React.Fragment key={m}>
            {(!excludeMenu || !excludeMenu.find((exclude) => exclude === m)) && (
              <li>
                <Button
                  size="small"
                  variant="territory"
                  className="--flex-x-center"
                  active={selectedMenu === m}
                  onClick={() => {
                    onChangeMenu(m)
                    changePath && Router.replace(newsPaths[m], undefined, { shallow: true })
                  }}>
                  {hasIcon && menuIcon[m] && <img src={menuIcon[m]} alt="" />}
                  {t(`menu.${m}`)}
                </Button>
              </li>
            )}
          </React.Fragment>
        ))}
      </ul>
      {typeof onChangeCountry === 'function' && <NewsCountries onChange={onChangeCountry} />}
    </StyledNewsNav>
  )
}

export default NewsNav
