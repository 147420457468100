import styled from 'styled-components'

const StyledNewsCountries = styled.div`
  > button {
    min-height: var(--spacing-legacy-25);

    .country-flag {
      width: var(--spacing-legacy-20);
      height: var(--spacing-legacy-20);
      margin-right: var(--spacing-legacy-5);
    }

    span {
      font-size: var(--font-size-14);
    }

    ::after {
      transform: rotateZ(135deg);
      margin-left: var(--spacing-legacy-8);
      margin-bottom: var(--spacing-legacy-5);
      width: var(--spacing-legacy-8);
      height: var(--spacing-legacy-8);
    }
  }
`

export default StyledNewsCountries
