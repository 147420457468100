import styled from 'styled-components'

const StyledNewsCountriesModal = styled.ul`
  li {
    display: inline-block;
    width: calc(50% - var(--spacing-legacy-6) * 2);
    margin: var(--spacing-legacy-6);

    button {
      text-align: left;
      padding: var(--spacing-legacy-12) var(--spacing-legacy-20);
      width: 100%;
      border-radius: var(--border-radius-12);
      border: 1px solid rgba(128, 128, 128, 0.2);
      font-size: var(--font-size-15);
      transition: border-color 0.2s;

      img {
        width: var(--spacing-legacy-20);
        margin-right: var(--spacing-legacy-10);
      }

      @media (hover: hover) and (pointer: fine) {
        :hover {
          border-color: var(--color-primary-a2);
        }
      }

      &.active {
        border-color: var(--color-primary-a2);
        background-color: var(--color-primary-a2);
        span {
          color: var(--color-primary);
        }
      }
    }
  }
`

export default StyledNewsCountriesModal
