import * as React from 'react'

import {
  ModalContainer,
  ModalBaseProps,
  ModalContent,
  ModalHeader,
} from '@/components/common/modal'
import useLanguage from '@/utils/language/language-hook'

import NewsKeywordsNotification from '..'

const NewsKeywordsNotificationModal = ({ isOpen, onClose }: ModalBaseProps) => {
  const { t } = useLanguage('account')

  return (
    <ModalContainer isOpen={isOpen} pcWidth={400} pcMinHeight={800} onClose={onClose}>
      <ModalContent
        header={
          <ModalHeader title={t('notificationSetting.newsKeywords.title')} onClose={onClose} />
        }>
        <NewsKeywordsNotification />
      </ModalContent>
    </ModalContainer>
  )
}

export default NewsKeywordsNotificationModal
