function makeDecimal({
  decimal,
  removeDecimalZero = false,
}: {
  decimal: string
  removeDecimalZero?: boolean
}): string {
  if (!decimal) {
    return ''
  }

  if (removeDecimalZero) {
    const removedZero = decimal.replace(/0+$/, '')
    return removedZero === '' ? '' : `.${removedZero}`
  }

  return `.${decimal}`
}

type Props = {
  value: string | number
  fixed?: string | number | null
  removeDecimalZero?: boolean
}

export default function threeComma({
  value,
  fixed = null,
  removeDecimalZero = false,
}: Props): string {
  if (!value) return '0'

  const [integer, decimal] =
    fixed === null
      ? value.toString().split('.') // value Number로 변경할 경우 6.8e-9 같은 지수로 표시됨
      : (+value)
          .toFixed(+(fixed || 0))
          .toString()
          .split('.')
  const integerWithComma = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  const decimalWithDot = makeDecimal({ decimal, removeDecimalZero })

  return `${integerWithComma}${decimalWithDot}`
}
