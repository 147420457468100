import styled from 'styled-components'

const StyledNewsNav = styled.div`
  li {
    display: inline-block;
    white-space: pre;

    @media (max-width: 767px) {
      :first-child {
        margin-left: var(--spacing-legacy-15);
      }
      :last-child {
        margin-right: var(--spacing-legacy-15);
      }
    }

    :not(:first-child) {
      margin-left: var(--spacing-legacy-4);
    }

    button {
      img {
        margin-right: var(--spacing-legacy-5);
      }
    }
  }
`

export default StyledNewsNav
