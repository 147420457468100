import * as React from 'react'

import { useExchangesStore } from '@/service/currency/store'

import fetchCurrencies from './fetch-currencies'
import _exchangeCurrency from './exchange'

export type CurrencyType = 'fiat' | 'crypto'

export type CurrencyExchangesProps = {
  fiatPairs?: { base: string; counter: string }[]
  cryptoPairs?: { base: string; counter: string }[]
}

export type ExchangeCurrency = ({ price, from, to, type }: ExchangeCurrencyRatioProps) => string

type ExchangeCurrencyRatioProps = {
  price: string | number
  from?: string | null
  to?: string | null
  type?: CurrencyType
}

export default function useCurrencyExchanges({
  fiatPairs = [],
  cryptoPairs = [],
}: CurrencyExchangesProps = {}) {
  const exchanges = useExchangesStore()
  const initRef = React.useRef(false)

  React.useMemo(() => {
    if (fiatPairs.length + cryptoPairs.length === 0) return

    // 최초 한번만 실행
    if (initRef.current) return
    initRef.current = true

    typeof global.window !== 'undefined' && fetchCurrencies({ fiatPairs, cryptoPairs })
  }, [cryptoPairs, fiatPairs])

  // 환율 변환
  const exchangeCurrency = React.useCallback(
    (props: ExchangeCurrencyRatioProps) => {
      return _exchangeCurrency({ ...props, exchanges })
    },
    [exchanges],
  )

  // 코인 exchanges data 반환(코인 가격을 표시할때 사용)
  const getCryptoCurrencyExchange = React.useCallback(
    ({ base, counter }: { base: string; counter: string }) => {
      return exchanges.crypto[`${base}${counter}`]
    },
    [exchanges.crypto],
  )

  return { exchanges, exchangeCurrency, getCryptoCurrencyExchange }
}
