import * as React from 'react'

import type { Menu } from '@/components/shape/news/news-nav'
import {
  SortType,
  useBusinessNewsQuery,
  useHoldingAssetNewsQuery,
  useSortedNewsQuery,
} from '@/gql/generated-news'
import { newsServerApolloClient } from '@/apollo-client'
import useLanguage from '@/utils/language/language-hook'
import { useMyMetaPortfolioQuery } from '@/gql/generated-api1'

import { useAuthTokenStore } from '../auth/store'

type Props = {
  type: Menu
  country: string
  limit?: number
}

export const newsPagePerLength = 20

export default function useNewsByType({ type, country, limit = newsPagePerLength }: Props) {
  const { language } = useLanguage()
  const { token } = useAuthTokenStore()
  const pageRef = React.useRef(0)
  const page = pageRef.current
  const holdingAssetsNewsResp = useHoldingAssetNewsQuery({
    variables: { country, limit, page },
    skip: type !== 'my' || !country || !token,
    client: newsServerApolloClient(),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })
  const todayPickNewsResp = useBusinessNewsQuery({
    variables: { country, limit, page },
    skip: type !== 'today-pick' || !country,
    client: newsServerApolloClient(),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })
  const sortedNewsResp = useSortedNewsQuery({
    variables: {
      sortType: type === 'recommend' ? SortType.RecommendedCount : SortType.ViewCount,
      language,
      country,
      limit,
      page,
    },
    skip: (type !== 'recommend' && type !== 'top') || !country,
    client: newsServerApolloClient(),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })
  const { data, loading: metaPortfolioLoading } = useMyMetaPortfolioQuery({
    skip: type !== 'my' || !token,
  })
  const isRequireSignin = React.useMemo(() => !token && type === 'my', [token, type])
  const isRequirePortfolios = React.useMemo(
    () =>
      type === 'my' &&
      !data?.myMetaPortfolio.portfolios?.find((portfolio) => !!portfolio.portfolioSwitchOn),
    [data?.myMetaPortfolio.portfolios, type],
  )

  const news =
    holdingAssetsNewsResp.data?.holdingAssetNews ||
    todayPickNewsResp.data?.businessNews ||
    sortedNewsResp.data?.sortedNews ||
    []

  const fetchMore = React.useCallback(() => {
    const page = (pageRef.current = pageRef.current + 1)
    switch (type) {
      case 'my':
        return holdingAssetsNewsResp.fetchMore({ variables: { page } })
      case 'today-pick':
        return todayPickNewsResp.fetchMore({ variables: { page } })
      case 'top':
      case 'recommend':
        return sortedNewsResp.fetchMore({ variables: { page } })
      default:
        return null
    }
  }, [holdingAssetsNewsResp, sortedNewsResp, todayPickNewsResp, type])

  // pageRef 노출 후 직접 초기화 하도록 변경.. react 버전 업그레이드 이후 아폴로 클라이언트가 먼저 반응 후 return 함수 실행하는 것으로 보임
  // type, country 변경시 page 초기화
  // React.useEffect(() => {
  //   return () => {
  //     pageRef.current = 0
  //   }
  // }, [type, country])

  return {
    news,
    loading:
      holdingAssetsNewsResp.loading ||
      todayPickNewsResp.loading ||
      sortedNewsResp.loading ||
      metaPortfolioLoading,
    isRequirePortfolios,
    fetchMore,
    pageRef,
    hasMore: (pageRef.current + 1) * newsPagePerLength <= Number(news?.length),
    isRequireSignin,
  }
}
