import useLanguage from '@/utils/language/language-hook'
import { LocaleEnum } from '@/types/locale'

const countryCodeByLocale = {
  [LocaleEnum.ko]: 'KR',
  [LocaleEnum.en]: 'US',
}

export default function useLanguageAsCountry(): { countryCode: string | null } {
  const { language } = useLanguage()
  return { countryCode: countryCodeByLocale[language] ?? null }
}
