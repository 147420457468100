import * as React from 'react'

import { NewsCountryFieldsFragment, useSupportedCountryForNewsQuery } from '@/gql/generated-news'
import { newsServerApolloClient } from '@/apollo-client'
import { getLocalStorage, setLocalStorage } from '@/utils/browser/localstorage'
import { LocalStorageEnum } from '@/constants/localstorage'
import useLanguageAsCountry from '@/utils/language/language-as-country-hook'
import useLanguage from '@/utils/language/language-hook'
import { useGetByLocaleName } from '@/utils/language'
import Image from '@/components/common/image'

import NewsCountriesModal from '../news-countries-modal'

import StyledNewsCountries from './styled'

type Props = {
  onChange: (countryCode: string) => void
}

const NewsCountries = ({ onChange }: Props) => {
  const { t } = useLanguage('news')
  const { countryCode } = useLanguageAsCountry()
  const getByLocaleName = useGetByLocaleName()
  const [selectedCountry, setSelectedCountry] = React.useState<NewsCountryFieldsFragment | null>(
    null,
  )
  const [isOpen, setIsOpen] = React.useState(false)
  const { data } = useSupportedCountryForNewsQuery({
    client: newsServerApolloClient(),
  })

  const setCountry = React.useCallback(
    (country: NewsCountryFieldsFragment) => {
      setLocalStorage({ key: LocalStorageEnum.NewsCountry, value: country })
      setSelectedCountry(country)
      country.countryCode && onChange(country.countryCode)
    },
    [onChange],
  )

  // 초기 설정
  React.useEffect(() => {
    if (!selectedCountry) {
      const country = getLocalStorage<NewsCountryFieldsFragment | null>({
        key: LocalStorageEnum.NewsCountry,
        type: 'object',
        fallbackValue: null,
      })
      if (country && typeof country !== 'string') {
        setCountry(country)
      } else {
        const country = data?.supportedCountryForNews?.find(
          (country) => country?.countryCode === countryCode,
        )
        country && setCountry(country)
      }
    }
  }, [countryCode, data?.supportedCountryForNews, selectedCountry, setCountry])

  return (
    <>
      {selectedCountry?.name && (
        <StyledNewsCountries>
          <button
            type="button"
            className="--flex-x-center --right-arrow"
            onClick={() => setIsOpen(true)}>
            {selectedCountry.imageUrl && (
              <div className="country-flag">
                <Image src={selectedCountry.imageUrl} alt={getByLocaleName(selectedCountry.name)} />
              </div>
            )}
            <span>
              {getByLocaleName(selectedCountry.name)} {t('news')}
            </span>
          </button>
        </StyledNewsCountries>
      )}
      <NewsCountriesModal
        isOpen={isOpen && !!data?.supportedCountryForNews && !!selectedCountry?.countryCode}
        onClose={() => setIsOpen(false)}
        countries={data?.supportedCountryForNews || []}
        onClick={setCountry}
        selectedCountryCode={selectedCountry?.countryCode}
      />
    </>
  )
}

export default NewsCountries
